/**
 * Listbox Utils | Search History & Autosuggest
 * Search History and Autosuggest are very similar in functionality, but must be able to work
 * independently of one another. This module is for methods that are used in both recents and
 * autosuggest listboxes.
 * @see [Definition of listbox]{@link https://www.w3.org/TR/wai-aria-practices/#Listbox}.
 * Used in reference to [combo box]{@link https://www.w3.org/TR/wai-aria-practices/#combobox}
 */
import { code, getKeyCode, keyCode } from '../../../../_shared-components/keyCode';
import LISTBOX from './constants';

/**
 * Keys whose default action should be ignored on a keydown event.
 * @param {KeyboardEvent} e
 */
const preventDefaultOnKeyDown = (e) => {
  const keyCodeVal = getKeyCode(e);
  switch (keyCodeVal) {
    case code.ARROW_DOWN:
    case keyCode.ARROW_DOWN:
    case code.ARROW_UP:
    case keyCode.ARROW_UP:
    case code.END:
    case keyCode.END:
      e.preventDefault();
      break;
    default:
      break;
  }
};

const getUpdatedIndexOnArrowDown = (currentIndex, length) => (
  (currentIndex < length - 1 && currentIndex > -1) ? currentIndex + 1 : 0
);

const getUpdatedIndexOnArrowUp = (currentIndex, length) => (
  (currentIndex <= length && currentIndex > 0) ? currentIndex - 1 : length - 1
);

/**
 * Sets the input field with the value.
 * @param {HTMLInputElement} searchField
 * @param {string} value
 */
const setSearchField = (searchField, value = '') => {
  const search = searchField;
  search.value = value;
};

/**
 * Iterates through list entries and removes css indicating visual focus.
 * @param {HTMLLIElement[]} listEntries
 */
const cleanListEntries = (listEntries) => {
  [...listEntries].forEach((elem) => {
    elem.classList.remove(LISTBOX.CSS.VISUAL_FOCUS);
    elem.removeAttribute('aria-selected');
  });
};

const resetListbox = (searchField, listEntries) => {
  searchField.setAttribute('aria-activedescendant', '');
  cleanListEntries(listEntries);
};

/**
 * Index for the list item element is the last substring of the id when split on '-'.
 * @param {HTMLLIElement} elem
 * @returns {number|number}
 */
const getIndexFromElement = (elem) => {
  const { id = null } = elem || {};
  const wordsArr = !id ? [] : id.split('-');
  return wordsArr.length < 1 ? -1 : (wordsArr[wordsArr.length - 1] * 1);
};

/**
 * Adds visual focus to a single listItem and updates aria-live region with descriptive context.
 * @param {HTMLInputElement} searchField
 * @param {HTMLLIElement} listItem - entry to add visual focus to
 * @param {HTMLLIElement[]} listEntries - button element only for clear history
 */
const setVisualFocus = (searchField, listItem, listEntries) => {
  cleanListEntries(listEntries);
  searchField.setAttribute('aria-activedescendant', listItem.id);
  listItem.setAttribute('aria-selected', 'true');
  listItem.classList.add(LISTBOX.CSS.VISUAL_FOCUS);
};

/**
 * @param {HTMLDivElement} listboxElem - which contains the data-visible attribute which may
 * need to be updated
 * @param {boolean} newValue - most recent result of logic determining if listbox should visible
 * (true) or hidden (false)
 * @returns {boolean} - true if a difference exists between the previous and newValue
 */
const shouldUpdateVisibility = (listboxElem, newValue) => {
  const { dataset: { visible: visibleValue } } = listboxElem;
  const oldValue = visibleValue === 'visible';
  return oldValue !== newValue;
};

const addMouseMoveEventListeners = (dynamicElem, enterCallback, leaveCallback) => {
  dynamicElem.addEventListener('mouseenter', enterCallback);
  dynamicElem.addEventListener('mouseleave', leaveCallback);
};

export {
  addMouseMoveEventListeners,
  getUpdatedIndexOnArrowDown,
  getUpdatedIndexOnArrowUp,
  getIndexFromElement,
  preventDefaultOnKeyDown,
  resetListbox,
  shouldUpdateVisibility,
  setSearchField,
  setVisualFocus,
};
