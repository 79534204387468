const SEARCH_HISTORY = {
  LI_ID: (index) => `search-history-entry-${index}`,
  UL_ID: 'gnav-recent-search-list',
  LOCAL_STORAGE_KEY: 'savedSearches',
  SELECTOR: '[data-js=recent-search-box]',
  RECENTLY_SELECTED: 'recentSearchTermSelected',
  DELETE: {
    ID: 'search-history-delete',
    CSS: 'search-history__button',
    TEXT: 'Clear History',
  },
  TITLE: {
    CSS: 'search-history__title',
    TEXT: 'Search History',
  },
};

const AUTOSUGGEST = {
  LI_ID: (index) => `autosuggest-result-${index}`,
  UL_ID: 'gnav-suggestion-list',
  SELECTOR: '[data-js=suggestion-box]',
  URL: {
    FALLBACK: 'https://www.rei.com/autosuggest/online/details',
  },
};

const LISTBOX = {
  CSS: {
    VISUAL_FOCUS: 'searchbox-term',
    LIST_ITEM: 'searchbox-result',
  },
  ARIA_LIVE: {
    DIRECTIONS: 'Keyboard users, use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.',
    SELECTOR: '[data-js="feedback-holder"]',
  },
  ALL_LI: {
    SELECTOR: `[id^=${AUTOSUGGEST.LI_ID('')}], [id^=${SEARCH_HISTORY.LI_ID('')}]`,
  },
  URL: {
    DOMAIN: 'https://www.rei.com/',
  },
};

export {
  AUTOSUGGEST,
  SEARCH_HISTORY,
};
export default LISTBOX;
