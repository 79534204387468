/**
 * Autosuggest
 * Contains main functionality for the search autosuggest.
 */
import { getDomElems } from '../../../../_shared-components/getDomElems';
import filterResults from './filterResults';
import getScriptData from '../../util/getScriptData';
import { getKeyCode, code, keyCode } from '../../../../_shared-components/keyCode';
import LISTBOX, { AUTOSUGGEST } from './constants';
import * as listbox from './listboxUtil';
import handleClickAnalyticsForSearch from './searchMetrics';

/**
 * State holds the values for autosuggest and includes
 * @param {array} fetchedItems which is the result of the fetch call,
 * @param {array} filteredResults is an array of ten results,
*/
const state = {
  fetchedItems: [],
  filteredResults: [],
};

/**
 * Pings an endpoint and assigns the results to state's fetchedItems property. If an error occurs,
 * it is logged to the dev console and fetchedItems is set to an empty array.
 * @param {string} fetchPoint: url to ping
 */
export const fetchData = (fetchPoint) => {
  fetch(fetchPoint)
    .then((response) => {
      const { ok, status, url } = response || {};
      if (!ok) {
        console.error(`url: ${url} is responding with status code: ${status}`);
      }
      return ok ? response.json() : [];
    })
    .then((response) => {
      state.fetchedItems = response;
    })
    .catch((e) => console.error(e));
};

/**
 * If resultTitle exists then returns string containing bolded substring via markup.
 * Otherwise returns unchanged searchTerm string.
 * @param {string} searchTerm
 * @param {string} resultTitle
 * @returns {*}
 */
const getInnerHtml = (searchTerm, resultTitle) => (
  resultTitle.replace(searchTerm, `<span style='font-weight:bolder;'>${searchTerm}</span>`)
);

/**
 * @param {number} resultCount - current number of available autosuggest options
 * @param {string} searchTerm - the characters contained within the search input
 * @returns {string} - description of available autosuggest options for a searchTerm,
 * including directions on how to navigation the open listbox.
 */
const getAriaLiveTextForResults = (resultCount, searchTerm) => (
  (resultCount === 1) ? `There is 1 result available that contains "${searchTerm}". ${LISTBOX.ARIA_LIVE.DIRECTIONS}`
    : `There are ${resultCount} results available that contain "${searchTerm}".${resultCount > 0 ? ` ${LISTBOX.ARIA_LIVE.DIRECTIONS}` : ''}`
);

/**
 * Will remove visual focus for autosuggest list item when mouse leaves element.
 * Updates aria-live text to total result count
 * @param e
 */
const onMouseLeave = (e) => {
  const { target } = e;
  const { suggestionBox, ariaLiveRegion, searchField } = state;
  const listEntries = suggestionBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR);
  /* only remove visual focus if target has it, otherwise do nothing */
  if (target.classList.contains(LISTBOX.CSS.VISUAL_FOCUS)) {
    listbox.resetListbox(searchField, listEntries);
    ariaLiveRegion.textContent = getAriaLiveTextForResults(
      listEntries.length,
      searchField.value.trim()
    );
  }
};

/**
 * Add visual focus to autosuggest list item.
 * @param e
 */
const onMouseEnter = (e) => {
  const { target } = e;
  const { suggestionBox, ariaLiveRegion, searchField } = state;
  const listEntries = suggestionBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR);
  listbox.setVisualFocus(searchField, target, listEntries);
  ariaLiveRegion.textContent = '';
};

/**
 * Creates and appends markup to the DOM containing the list of autosuggest options with the
 * relevant characters bolded.
 * @param {object[]} results Array of (at most 10) options
 */
export const appendResults = (results) => {
  const { suggestionList } = state;
  /* remove contents from ul element */
  const ul = suggestionList;
  ul.innerHTML = '';
  /* add list items to ul */
  [...results].forEach(({ searchTerm, resultTitle }, index) => {
    const li = document.createElement('li');
    li.className = LISTBOX.CSS.LIST_ITEM;
    /* id with index used for tracking focus & building metrics */
    li.id = AUTOSUGGEST.LI_ID(index);
    li.innerHTML = getInnerHtml(searchTerm, resultTitle);
    li.setAttribute('role', 'option');
    li.setAttribute('aria-label', resultTitle);
    listbox.addMouseMoveEventListeners(li, onMouseEnter, onMouseLeave);
    ul.appendChild(li);
  });
};

/**
 * Controls visibility / expand-collapse of autosuggestion listbox
 * @param {HTMLDivElement} suggestionBox - element that contains the list entries of recent
 * searches or filtered results
 * @param {HTMLInputElement} searchField - input element of search field
 * @param {boolean} isVisible - boolean used to determine state of visibility / expansion
 */
export const setSuggestionBoxVisibility = (suggestionBox, searchField, isVisible = false) => {
  if (!isVisible) {
    listbox.resetListbox(searchField, [...suggestionBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR)]);
  }
  searchField.parentElement.setAttribute('aria-expanded', `${isVisible}`);
  suggestionBox.setAttribute('data-visible', isVisible ? 'visible' : 'hidden');
};

/**
 * Handles the keyboard logic for list entries in SuggestionBox
 * @param e
 */
export const handleKeyPress = (e) => {
  const {
    ariaLiveRegion,
    suggestionBox,
    searchField,
  } = state;
  const { dataset: { visible } } = suggestionBox;
  const listEntries = suggestionBox.querySelectorAll(`.${LISTBOX.CSS.LIST_ITEM}`);
  const currentIndex = listbox.getIndexFromElement(suggestionBox.querySelector(`.${LISTBOX.CSS.VISUAL_FOCUS}`));
  const keyCodeVal = getKeyCode(e);
  const searchTerm = searchField.value.trim();
  switch (keyCodeVal) {
    case code.ARROW_DOWN:
    case keyCode.ARROW_DOWN:
      if (visible === 'visible') {
        const updatedIndex = listbox.getUpdatedIndexOnArrowDown(currentIndex, listEntries.length);
        listbox.setVisualFocus(searchField, listEntries[updatedIndex], listEntries);
        ariaLiveRegion.textContent = '';
      }
      break;
    case code.ARROW_UP:
    case keyCode.ARROW_UP:
      if (visible === 'visible') {
        const updatedIndex = listbox.getUpdatedIndexOnArrowUp(currentIndex, listEntries.length);
        listbox.setVisualFocus(searchField, listEntries[updatedIndex], listEntries);
        ariaLiveRegion.textContent = '';
      }
      break;
    case code.ESCAPE:
    case keyCode.ESCAPE:
      if (visible === 'visible') {
        listbox.setSearchField(searchField);
        searchField.focus();
        setSuggestionBoxVisibility(suggestionBox, searchField);
        ariaLiveRegion.textContent = '';
      }
      break;
    case code.END:
    case keyCode.END:
      if (visible === 'visible') {
        searchField.focus();
        /* places cursor at the end text in field (if any) */
        searchField.setSelectionRange(searchField.value.length, searchField.value.length);
        listbox.resetListbox(searchField, listEntries);
        ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);
      }
      break;
    case code.HOME:
    case keyCode.HOME:
      if (visible === 'visible') {
        searchField.focus();
        listbox.resetListbox(searchField, listEntries);
        ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);
      }
      break;
    case code.ARROW_LEFT:
    case keyCode.ARROW_LEFT:
    case code.ARROW_RIGHT:
    case keyCode.ARROW_RIGHT:
      if (visible === 'visible') {
        listbox.resetListbox(searchField, listEntries);
        ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);
      }
      break;
    default:
      if (searchTerm.length > 0) {
        ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);
      }
      break;
  }
};

const onFocus = (suggestionBox, e) => {
  const { target: searchField } = e;
  const searchTerm = searchField.value.trim();
  if (searchTerm.length > 0) {
    /* update state */
    state.filteredResults = filterResults(searchTerm, state);
    const { ariaLiveRegion, filteredResults } = state;
    appendResults(filteredResults);
    const isVisible = (filteredResults && filteredResults.length > 0);
    if (listbox.shouldUpdateVisibility(suggestionBox, isVisible)) {
      setSuggestionBoxVisibility(suggestionBox, searchField, isVisible);
    }
    ariaLiveRegion.textContent = getAriaLiveTextForResults(filteredResults.length, searchTerm);
  }
};

const onBlur = (suggestionBox, e) => {
  const { ariaLiveRegion } = state;
  const { target: searchField } = e;
  setSuggestionBoxVisibility(suggestionBox, searchField);
  ariaLiveRegion.textContent = '';
};

/**
 * Updates filteredResults, DOM, and/or toggles visibility as needed before invoking
 * handleKeyPress method.
 * @param {HTMLDivElement} suggestionBox
 * @param {HTMLInputElement} searchField
 * @param {KeyboardEvent} e
 */
const onInput = (suggestionBox, searchField, e) => {
  const searchTerm = searchField.value.trim();
  /* only update DOM when necessary */
  state.filteredResults = filterResults(searchTerm, state);
  appendResults(state.filteredResults);

  const isVisible = (searchTerm !== ''
    && (state.filteredResults && state.filteredResults.length > 0));
  if (listbox.shouldUpdateVisibility(suggestionBox, isVisible)) {
    setSuggestionBoxVisibility(suggestionBox, searchField, isVisible);
  }

  // needed to update aria live region
  handleKeyPress(e);
};

const onFormSubmit = () => {
  const {
    suggestionBox,
    searchField,
  } = state;
  const { dataset: { visible } } = suggestionBox || {};
  const searchBoxTerm = suggestionBox.querySelector(`.${LISTBOX.CSS.VISUAL_FOCUS}`);
  if (searchBoxTerm && visible === 'visible') {
    listbox.setSearchField(searchField, searchBoxTerm.innerText.trim());
    handleClickAnalyticsForSearch(searchBoxTerm);
  } else {
    /* search term is typed by user (not a selected entry within the listbox) */
    handleClickAnalyticsForSearch(null, {
      linkName: 'rei_nav:searchbutton',
      searchTermType: 'natural',
      searchTermClicked: searchField.value,
      events: 'event1',
    });
  }
};

const onMouseDown = (searchForm, searchField, e) => {
  const { target } = e;
  const { msMatchesSelector } = target;
  if (!target.matches) {
    /* IE11 Support */
    target.matches = msMatchesSelector;
  }
  if (target && (target.matches(`li.${LISTBOX.CSS.LIST_ITEM}`) || target.matches('span'))) {
    const resultEntry = target.matches('span') ? target.parentNode : target;
    const value = (resultEntry.innerText).trim();
    listbox.setSearchField(searchField, value);
    handleClickAnalyticsForSearch(resultEntry);
    searchForm.submit();
  }
};
/**
 * Grabs autoSuggestTarget from #gnav-data blob. If not found uses absolute url fallback value
 * @returns {string} url
 */
const getAutosuggestTarget = () => {
  const {
    autosuggestTarget: targetUrl = null,
  } = getScriptData('gnav-data') || {};
  return targetUrl === null ? AUTOSUGGEST.URL.FALLBACK : targetUrl;
};

/* init */
const init = (selectors) => {
  const searchElems = getDomElems(selectors);
  if (searchElems === null) return;
  /* build state */
  const {
    searchField,
    suggestionBox,
    suggestionList,
    searchForm,
  } = searchElems;
  const ariaLiveRegion = searchForm.parentElement.querySelector(LISTBOX.ARIA_LIVE.SELECTOR);
  searchField.setAttribute('aria-activedescendant', '');
  Object.assign(state, searchElems, { ariaLiveRegion });
  /* Add Event Listeners */
  searchField.addEventListener('focus', onFocus.bind(null, suggestionBox));
  searchField.addEventListener('blur', onBlur.bind(null, suggestionBox));
  searchField.addEventListener('input', onInput.bind(null, suggestionBox, searchField));
  searchField.addEventListener('keydown', listbox.preventDefaultOnKeyDown);
  searchField.addEventListener('keyup', handleKeyPress);
  searchForm.addEventListener('submit', onFormSubmit);
  suggestionList.addEventListener('mousedown', onMouseDown.bind(null, searchForm, searchField));
  fetchData(getAutosuggestTarget());
};

export { state as suggestState };
export default init;
