import '../../../../../util/global-shim';
import { main } from './main';
import '../../../../../../style/navigation/global/components/search/base-search.scss';
/**
 * This is the main js entry point for search component(s)
 *
 * @param win Window object. Pass in to allow for window stubbing.
 * @returns {Window}
 */
main(window);
