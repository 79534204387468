import { analyticsCustomClickHandler } from '../../../../_shared-components/navigationMetrics';

const isSearchHistoryEntry = (elemId) => (elemId.startsWith('search-history'));

/**
 * Determines conditional properties based on whether the resultEntry was selected from the
 * Search History (aka Recent Searches) or Autosuggest listbox. Extracts position in list
 * by parsing the element's id
 * @param elemId: should have structure of "listboxType-...-index", ex. "autosuggest-result-0"
 * @returns {{autoSuggestPosition: (string|string), recentQuery: (string), searchTermType: string}}
 */
const getConditionalProps = (elemId) => {
  const wordsArr = elemId.split('-');
  const autoSuggestPosition = wordsArr[wordsArr.length - 1];
  const searchTermType = `autosuggest${isSearchHistoryEntry(elemId) ? '-recentquery' : ''}`;
  const recentQuery = isSearchHistoryEntry(elemId) ? 'yes' : 'no';
  return { autoSuggestPosition, recentQuery, searchTermType };
};

/**
 * Helper method for building custom config for @rei/metrics click on an open listbox
 * (recent searches or autosuggest). May be removed in near future, as this is working
 * with legacy code.
 * @param {HTMLLIElement} resultEntry
 * @returns {{autoSuggestPosition: (string|number), recentQuery: (string),
 * searchTermType: string, searchTermClicked: string, linkName: string, events: string}}
 */
const buildListboxClickAnalyticsConfig = (resultEntry) => {
  const { id = '', innerText = '' } = resultEntry || {};
  return {
    ...getConditionalProps(id),
    linkName: 'searchbox-_-text-_-autosuggest',
    searchTermClicked: (innerText).trim(),
    events: 'event1',
  };
};

/**
 * Builds a click analytics config for supported elements within search form. Then triggers
 * metrics click handler with resulting config.
 * @param {HTMLLIElement} resultEntry: selected result from open listbox
 * @param {object} altConfig: optional object config to handle metrics from non-listbox
 *        search elements (ex. input)
 */
const handleClickAnalyticsForSearch = (resultEntry, altConfig = null) => {
  const configToUse = altConfig === null
    ? buildListboxClickAnalyticsConfig(resultEntry) : altConfig;
  analyticsCustomClickHandler(configToUse);
  return configToUse;
};

export default handleClickAnalyticsForSearch;
