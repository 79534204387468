/** Search Form Expand / Contract */

import { anchorOrButtonEvents } from '@rei/click-event-pubsub';
import { getDomElems } from '../../../../_shared-components/getDomElems';
import getScriptData from '../../util/getScriptData';
import { isDesktop, isMobile } from '../../util/getScreenSize';
import { SEARCH_HISTORY } from './constants';
import { setSearchField } from './listboxUtil';

const getPlaceholderText = () => {
  const {
    searchPlaceholderDesktop = 'Search for great gear & clothing',
    searchPlaceholderMobile = 'Search',
  } = getScriptData('gnav-data') || {};
  return isDesktop() ? searchPlaceholderDesktop : searchPlaceholderMobile;
};

/* basic expand */
export const expand = (masthead, searchField) => {
  masthead.setAttribute('data-expanded', 'true');
  searchField.setAttribute('placeholder', getPlaceholderText());
  searchField.focus();
};

/* basic contract */
const contract = (masthead, searchField) => {
  masthead.setAttribute('data-expanded', 'false');
  searchField.setAttribute('placeholder', getPlaceholderText());
};

/* contract if empty */
const contractIfEmpty = (masthead, searchField, searchButton) => {
  const { value = null } = searchField || {};
  if (!value) {
    contract(masthead, searchField);
    if (isMobile()) {
      searchButton.focus();
    }
  }
};

/* either expand, search, or do nothing */
/* depending on the expand state and search field value */
const expandOrSearch = (e, masthead, searchField) => {
  const { dataset: { expanded = '' } = {} } = masthead || {};
  if (expanded === 'false') {
    e.preventDefault();
    expand(masthead, searchField);
  }
};

/* handling click events  - search / cancel */
const handleClicks = (searchElems, e, subscriber) => {
  const {
    masthead,
    searchField,
    searchButton,
    searchCancel,
  } = searchElems;
  if (subscriber === searchButton) {
    expandOrSearch(e, masthead, searchField);
    return;
  }
  if (subscriber === searchCancel) {
    contract(masthead, searchField);
  }
};

/* gets search param, if present */
const getSearchParam = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

const showClearButton = (searchField, clearButton) => {
  clearButton.removeAttribute('aria-hidden');
  clearButton.setAttribute('data-visible', 'visible');
  searchField.setAttribute('style', 'margin-right: -44px;');
};

const hideClearButton = (searchField, clearButton) => {
  clearButton.setAttribute('aria-hidden', 'true');
  clearButton.setAttribute('data-visible', 'hidden');
  searchField.setAttribute('style', 'margin-right: 0;');
};

const setClearButtonVisibility = (searchField, clearButton) => {
  if (!searchField.value) {
    hideClearButton(searchField, clearButton);
  } else {
    showClearButton(searchField, clearButton);
  }
};

const enableSearchButton = (searchField, searchButton) => {
  if (searchField.value.trim().length === 0) {
    searchButton.classList.add('search__search-button--disabled');
    searchButton.setAttribute('aria-disabled', 'true');
    searchButton.setAttribute('disabled', 'disabled');
  } else if (searchField.value.trim().length > 0) {
    searchButton.classList.remove('search__search-button--disabled');
    searchButton.setAttribute('aria-disabled', 'false');
    searchButton.removeAttribute('disabled');
  }
};

const enableSearchFormSubmit = (searchForm, searchField) => {
  const recentSearchTermSelected = localStorage.getItem(SEARCH_HISTORY.RECENTLY_SELECTED);
  const recentHistoryBox = document.querySelector(SEARCH_HISTORY.SELECTOR);

  if (searchField.value.trim().length === 0
  && (!recentSearchTermSelected || recentSearchTermSelected === SEARCH_HISTORY.DELETE.TEXT)) {
    searchField.setAttribute('required', 'required');
    if (recentSearchTermSelected === SEARCH_HISTORY.DELETE.TEXT) {
      localStorage.removeItem(SEARCH_HISTORY.LOCAL_STORAGE_KEY);
      setTimeout(() => {
        recentHistoryBox?.setAttribute('data-visible', 'hidden');
        recentHistoryBox?.setAttribute('style', 'display: none;');
        searchField.parentElement.setAttribute('aria-expanded', 'false');
      }, 200);
    }
  } else {
    searchField.removeAttribute('required');
    setSearchField(searchField, searchField.value || recentSearchTermSelected);
    searchForm.submit();
  }
};

const updateSearchField = (searchField) => {
  const inputElem = searchField;
  /* clear input */
  inputElem.value = '';
  searchField.focus();
};

/* init */
const init = (selectors) => {
  const searchElems = getDomElems(selectors);
  if (searchElems === null) return;

  const {
    masthead,
    searchForm,
    searchField,
    clearButton,
    searchButton,
  } = searchElems;

  searchField.addEventListener('focus', expand.bind(null, masthead, searchField));
  // This functionality seems to only be tied to mobile when search allows hidden
  // TODO: If B wins in A/B testing this should be able to be removed else put back if A wins
  //   The use of data-expanded in masthead will not be needed if B wins
  //   Remove the JS that adds the expand functionality to search button if B wins
  //   Remove search__search-button--disabled class from search button and the JS for it if B wins
  if (!masthead.classList.contains('search-open-on-mobile')) {
    searchField.addEventListener('blur', contractIfEmpty.bind(null, masthead, searchField, searchButton));
  }
  searchField.addEventListener('keyup', setClearButtonVisibility.bind(null, searchField, clearButton));
  anchorOrButtonEvents.push(handleClicks.bind(null, searchElems));
  searchField.value = getSearchParam('q');
  setClearButtonVisibility(searchField, clearButton);
  enableSearchButton(searchField, searchButton);
  clearButton.addEventListener('click', () => {
    updateSearchField(searchField);
    setClearButtonVisibility(searchField, clearButton);
    enableSearchButton(searchField, searchButton);
  });
  searchField.addEventListener('input', enableSearchButton.bind(null, searchField, searchButton));
  searchField.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
      enableSearchFormSubmit(searchForm, searchField);
      localStorage.removeItem(SEARCH_HISTORY.RECENTLY_SELECTED);
    }
  });
};

export default init;
