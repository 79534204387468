const sliceSize = 10;

export const cleanSearchResult = (item) => item.replace(/\s?-?'?/gi, '');

export const getStartsWithData = (data, cleanedSearchTerm) => data
  .filter((item) => cleanSearchResult(item).startsWith(cleanedSearchTerm));

export const getIncludesData = (data, cleanedSearchTerm) => data
  .filter((item) => !cleanSearchResult(item).startsWith(cleanedSearchTerm)
    && cleanSearchResult(item).includes(cleanedSearchTerm));

export const partitionData = (data, cleanSearchTerm) => data.map((term) => {
  // Build a regular expression pattern with optional special characters
  // in-between each letter of the search term
  let optionalSpecialCharacterPattern = '';
  for (let i = 0, len = cleanSearchTerm.length; i < len; i += 1) {
    optionalSpecialCharacterPattern += `${cleanSearchTerm[i]}\\W*`;
  }

  // Create the regular expression, ignoring case and matching only the first occurrence
  const optionalSpecialCharacterRegExp = new RegExp(optionalSpecialCharacterPattern, 'i');

  // Match the search term with optional special characters to the item in the term list
  const matches = term.match(optionalSpecialCharacterRegExp);

  // Define the parameters that allow search term highlighting in the term
  // list, note there should only ever be one occurrence
  const displayTerm = matches ? matches[0] : cleanSearchTerm;

  return {
    searchTerm: displayTerm,
    resultTitle: term,
  };
});

export const cleanSearchTerm = (term) => {
  // Regular expression to match all non-word (special) characters
  const specialCharacterRegExp = term.length > 1 ? /\W/g : '';

  return term.toLowerCase().replace(/[^a-z\d]/gi, '').replace(specialCharacterRegExp, '');
};

export const cleanFetchedItems = (fetchedItems) => [...fetchedItems]
  .map((item) => item.term.toLowerCase());

/** @param {function} filterResults takes @param {array} fetchedItems and filters it by using
  * the current value from the input box, as well as multiple steps of regex that return
  * matched string characters to allow for dynamic styling
  */
export default (value = '', state = {}) => {
  const newState = state;
  /** @param {function} filterResults takes @param {array} fetchedItems and filters it by using
    * the current value from the input box, as well as multiple steps of regex that return
    * matched string characters to allow for dynamic styling
    */
  const cleanedSearchTerm = cleanSearchTerm(value);

  if (!newState.fetchedItems.length) return [];
  // Grab all the values from fetchedItems and map over them to produce a
  // new array that has them lowercased and with only their term
  let data = cleanFetchedItems(newState.fetchedItems);

  // Grabs data that starts with search term
  const startsWithData = getStartsWithData(data, cleanedSearchTerm);

  // Grabs data that contains, but doesn't start with search term
  const includesData = getIncludesData(data, cleanedSearchTerm);

  // Combines data sets, if needed
  data = (startsWithData.length < sliceSize) ? startsWithData.concat(includesData) : startsWithData;

  // partition search results into the entered chars and remaining chars
  data = partitionData(data, cleanedSearchTerm);

  newState.filteredResults = data.slice(0, sliceSize);
  return newState.filteredResults;
};
